
  .switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 36px;
    padding: 1px;
    border-radius: 0px;
    cursor: pointer;
  }
  
  .switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 15px;
    background: #4ed472;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background;
    -moz-transition-property: opacity background;
    -o-transition-property: opacity background;
    transition-property: opacity background;
  }
  .switch-label:before, .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;

  }
  .switch-label:before {
    content: attr(data-off);
    left: 7px;
    color: white;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  }
  .switch-label:after {
    content: attr(data-on);
    right: 10px;
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
  }


  .switch-input:checked ~ .switch-label {
    background: #1c80e4;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .switch-input:checked ~ .switch-label:before {
    opacity: 0;
  }
  .switch-input:checked ~ .switch-label:after {
    opacity: 1;
  }
  
  .switch-handle {
    position: absolute;
    top: 1px;
    right: 1px;
    /* left: -5px; */
    width: 36px;
    height: 36px;
    background: white;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: right 0.15s ease-out;
    -o-transition: right 0.15s ease-out;
    transition: right 0.15s ease-out;
  }
  .switch-handle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    background: #f9f9f9;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  }
  .switch-input:checked ~ .switch-handle {
    right: 115px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
  }
  
  .switch-green > .switch-input:checked ~ .switch-label {
    background: #4fb845;
  }
  