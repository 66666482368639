@keyframes move-it {
    0% {
      background-position: initial;
    }
    100% {
      background-position: 600px 0px;
    }
  }
  
  #stripe {  
    background: repeating-linear-gradient(
    45deg,
    #fcf5e1,
    #fcf5e1 5%,
    #d9cca7 5%,
    #d9cca7 10%
  );
    background-size: 600px 600px;
    animation: move-it 10s linear infinite;
  }

@media (min-height: 0px){
#stripe  {
        height: 100%;
}
}


@media (min-height: 700px){
    #stripe  {
            height: 100vh;
    }
    }
