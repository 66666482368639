#sidebar {
  position: fixed;
  top: 0;
  right: 0;
}

#sidebar-btn {
  background: transparent;
}

#sidebar-btn:hover {
  background: #1c80e4;
}

.btn {
  background: transparent;
  color: black;
  border-color: black;
  border-width: 2px;
  border-radius: 0;
  font-size: 18;
  font-weight: bolder;
  font-family: "Hachi Maru Pop", serif;
}

.btn:hover {
  background: #1c80e4;
}

#drop-item:hover{
  background: #1c80e4;

}

#drop-item-logout:hover{
  background: red;

}

#small-tag {
  background: #ffffff;
}

#small-tag:hover {
  background: #1c80e4;
}


#enter-email-input::placeholder {
  font-family: "Hachi Maru Pop", serif;
}

#video-tags-input::placeholder {
  word-break: break-all;
}

#profile-image:hover {
  filter: brightness(95%);
}

/* File Input //////////////////////////////////////////////////////////////*/
.inputWrapper {
  height: 120px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /*Using a background color, but you can use a background image to represent a button*/
  background-color: #212529;
}
.fileInput {
  cursor: pointer;
  height: 100%;
  position:absolute;
  top: 0;
  right: 0;
  z-index: 99;
  /*This makes the button huge. If you want a bigger button, increase the font size*/
  font-size:50px;
  /*Opacity settings for all browsers*/
  -moz-opacity: 0;
  opacity: 0;
  /* filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0) */
}
/*///////////////////////////////////////////////////////////////////////////*/

#videos-views {
  left: 0;
}

#videos-likes {
  right: 0;
}

#videos-tags-div {
  left: 0;
}

#video{
  border: solid;
  border-width: 3px;
}

#video:hover{
  /* border-radius: 0px 0px 15px 15px; */
  border-width: 0px;
  transition-property: border-width;
  transition-duration: 0.2s;
}

#background-video {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: initial;
  z-index: -1;
}

.dropdown-toggle::after {
  display: none !important; 
}

@media (min-width: 0px) {
  #videostream-video-register-btn {
    margin-right: 0px;
  }
}
@media (min-width: 370px) {
  #videostream-video-register-btn {
    margin-right: 27px;
  }
}

@media (max-width: 1400px) {
  #register-form-lg {
    margin-top: 111px;
  }
}

@media (min-width: 1401px) {
  #register-form-lg {
    margin-top: 200px;
  }
}

@media (min-width: 0px) {
  #register-form-lg {
    width: 100%;
  }
}

@media (min-width: 700px) {
  #register-form-lg {
    width: 75%;
  }
}

@media (min-width: 1400px) {
  #register-form-lg {
    width: 70%;
  }
}

@media (min-width: 1700px) {
  #register-form-lg {
    width: 50%;
  }
}

@media (min-width: 992px) {
  #login-form-row {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  #login-form-row {
    width: 70%;
  }
}

@media (min-width: 1401px) {
  #login-form-row {
    width: 60%;
  }
}

@media (max-width: 991px) {
  #login-column-1 {
    width: 100%;
  }
}

@media (max-width: 991px) {
  #login-column-3 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #login-w-google-btn {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  #login-form-row {
    margin-top: 110px;
  }
}

@media (min-width: 992px) {
  #login-form-row {
    margin-top: 195px;
  }
}

@media (min-width: 768px) {
  #upload-video-form {
    width: 600px;
  }
}

@media (min-width: 0px) {
  #upload-video-form {
    margin-top: 109px;
  }
}

@media (min-width: 1400px) {
  #upload-video-form {
    width: 500px;
    margin-top: 200px;
  }
}

@media (min-width: 0px) {
  #navbar-brand {
    margin-left: 10px;
  }
}

@media (min-width: 900px) {
  #navbar-brand {
    margin-left: 100px;
  }
}

@media (min-width: 1800px) {
  #navbar-brand {
    margin-left: 160px;
  }
}

@media (min-width: 0px){
  #login-btn  {
    width: 8%;
  }
}

@media (min-width: 1200px){
  #login-btn  {
    width: 5%;
  }
}

@media (min-width: 992px){
  #explore-users-column  {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1550px){
  #explore-users-column  {
    padding-right: 130px;
    padding-left: 130px;
  }
}

@media (min-width: 1850px){
  #explore-users-column  {
    padding-right: 165px;
    padding-left: 165px;
  }
}


@media (min-width: 1011px){
  #sort-by-tag  {
    padding-right: 11px;
    padding-left: 11px;
  }
}

@media (max-width: 1010px){
  #sort-by-tag  {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 0px) {
  #about-text {
    width: 100%;
  }
}

@media (min-width: 576px) {
  #about-text {
    width: 80%;
  }
}

@media (min-width: 768px) {
  #about-text {
    width: 50%;
  }
}

@media (min-width: 1400px) {
  #about-text {
    width: 40%;
  }
}

@media (min-width: 0px) {
  #profile-backgournd-row {
    max-height: 200px;
  }
}

@media (min-width: 576px) {
  #profile-backgournd-row {
    max-height: 250px;
  }
}

@media (min-width: 992px) {
  #profile-backgournd-row {
    max-height: 300px;
  }
}

@media (min-width: 1800px) {
  #profile-backgournd-row {
    max-height: 350px;
  }
}

@media (max-width: 575px) {
  #follow-btn {
    display: none;
  }
}

@media (max-width: 575px) {
  #followers-badge {
    display: none;
  }
}

@media (min-width: 576px) {
  #followers-badge {
    display: flex;
  }
}

@media (min-width: 0px) {
  #user-card {
    margin-right: 0px;
  }
}

@media (min-width: 650px) {
  #user-card {
    margin-right: 50px;
  }
}

@media (min-width: 0px) {
  #search-bar {
    display: none;
  }
}

@media (min-width: 576px) {
  #search-bar {
    display: flex;
  }
}

@media (min-width: 0px) {
  #search-icon {
    display: flex;
  }
}

@media (min-width: 576px) {
  #search-icon {
    display: none;
  }
}

@media (min-width: 0px) {
  #search-bar-row-sm {
    display: flex;
  }
}

@media (min-width: 576px) {
  #search-bar-row-sm {
    display: none;
  }
}


@media (min-width: 0px) {
  #search-bar {
    display: flex;
    position: absolute;
    margin-top: 100px;
    margin-left: 4%;
  }
}

@media (min-width: 480px) {
  #search-bar {
    display: flex;
    position: absolute;
    margin-top: 100px;
    margin-left: 19%;
  }
}

@media (min-width: 577px) {
  #search-bar {
    display: flex;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
  }
}

#comments-list {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
#comments-list::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

@media (min-width: 500px) {
#video-container-2 {
/* width : 490px; */
height: auto;
border-style: solid;
border-color: black;
border-width: 4px;
border-radius: 35px;
}



#video-2 {
border: 2px solid;
border-radius: 35px 35px 0 0;
}
#video-bottombar {
border-style: solid;
border-color: black;
border-width: 4px 0 0 0;
border-radius: 0 0 35px 35px; 
}
#video-bar-comment{
border-radius:  0 0 0 35px
}
#video-bar-like{
border-radius:  0 0 35px 0 
}
}

@media (min-height: 0px) {
#views-tags-col{
margin-top: -38vh;
}
#userpic-likes-col{
margin-top: -70vh;
}}

@media (min-height: 460px) {
#views-tags-col{
margin-top: -31vh;
}
#userpic-likes-col{
margin-top: -40vh;
}}

@media (min-height: 700px) {
#views-tags-col{
margin-top: -25vh;
}
#userpic-likes-col{
margin-top: -34vh;
}}

@media (min-height: 800px) {
#views-tags-col{
margin-top: -23vh;
}
#userpic-likes-col{
margin-top: -30vh;
}}

@media (min-width: 0px) {
  #comment-input {
    width: 90%;
  }
}

@media (min-width: 500px) {
  #comment-input {
    width: 70%;
  }
}

@media (min-width: 700px) {
  #comment-input {
    width: 60%;
  }
}

@media (min-width: 800px) {
  #comment-input {
    width: 50%;
  }
} 

@media (min-width: 1000px) {
  #comment-input {
    width: 30%;
  }
}

@media (min-width: 1600px) {
  #comment-input {
    width: 22%;
  }
}

@media (min-width: 0px) {
  #videos-profilepic-username {
    margin-top: 18vh;
    margin-right: -8px;
  }
}

@media (min-width: 992px) {
  #videos-profilepic-username {
    margin-top: 18vh;
    margin-right: 210px;
  }
}

@media (min-width: 1300px) {
  #videos-profilepic-username {
    margin-top: 18vh;
    margin-right: 400px;
  }
}

@media (min-width: 1700px) {
  #videos-profilepic-username {
    margin-top: 18vh;
    margin-right: 550px;
  }
}

@media (min-width: 0px) {
  #videos-likes {
    margin-top: 42vh;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  #videos-likes {
    margin-top: 42vh;
    margin-right: 230px;
  }
}

@media (min-width: 1300px) {
  #videos-likes {
    margin-top: 42vh;
    margin-right: 418px;
  }
}

@media (min-width: 1700px) {
  #videos-likes {
    margin-top: 42vh;
    margin-right: 618px;
  }
}

@media (min-width: 0px) {
  #videos-views {
    margin-top: 40vh;
    margin-left: 13px;
  }
}

@media (min-width: 992px) {
  #videos-views {
    margin-top: 40vh;
    margin-left: 220px;
  }
}

@media (min-width: 1300px) {
  #videos-views {
    margin-top: 40vh;
    margin-left: 410px;
  }
}

@media (min-width: 1700px) {
  #videos-views {
    margin-top: 40vh;
    margin-left: 610px;
  }
}

@media (min-width: 0px) {
  #videos-tags-div {
    margin-top: 52vh;
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  #videos-tags-div {
    margin-top: 52vh;
    margin-left: 212px;
  }
}

@media (min-width: 1300px) {
  #videos-tags-div {
    margin-top: 52vh;
    margin-left: 405px;
  }
}

@media (min-width: 1700px) {
  #videos-tags-div {
    margin-top: 52vh;
    margin-left: 605px;
  }
}

@media (min-width: 0px) {
  #password-reset-container {
    width: 100%;
    margin-top: 100px;
  }

  #password-reset-form {
    width: 100%;
  }
}

@media (min-width: 800px) {
  #password-reset-container {
    width: 100%;
  }

  #password-reset-form {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  #password-reset-container {
    width: 70%;
    margin-top: 150px;

  }

  #password-reset-form {
    width: 70%;
  }
}

@media (min-width: 1600px) {
  #password-reset-container {
    width: 50%;
  }

  #password-reset-form {
    width: 70%;
  }
}

@media (min-width: 0px) {
  #video-card {
    margin-left: 40px;
  }
}

@media (min-width: 640) {
  #video-card {
    margin-left: 0px;
  }
}