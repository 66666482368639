
@media (min-height: 0px) {
    #app-gif {
        height: 70%;
    }
    #gif-discription {
        height: 30%;
    }
}
@media (min-height: 500px) {
    #app-gif {
        height: 80%;
    }
    #gif-discription {
        height: 20%;
    }
}
@media (min-height: 800px) {
    #app-gif {
        height: 90%;
    }
    #gif-discription {
        height: 10%;
    }
}

@media (max-width: 600px) and (max-height: 1000px) {
    #app-gif {
        height: 85%;
    }
    #gif-discription {
        height: 15%;
    }
}
@media (max-width: 700px) {
    #download-link {
        width: 90%;
    }
}
@media (min-width: 701px) {
    #download-link {
        width: 50%;
    }
}